/*----------  Header  ----------*/

header
{
	@include position(fixed, 9);
	left: 0;
	right: 0;
	top: 1;

	.main-navbar-container
	{
		@include transition;
		padding: 11px 0;
		.navbar-header
		{
			display: inline-block;
			@include clearfix;
			@media (max-width: 1050px) 
			{
				display: block;
				text-align: center;
			}
		}

		.navbar-brand
		{
			img
			{
				@include transition;
				width: 250px;

				@media (max-width: 1160px) 
				{
					width: 200px;
				}
				@media (max-width: 1050px) 
				{
					width: 250px;
				}
				@include media-breakpoint-down(xs)
				{
					width: 150px;
				}
			}

			@include media-breakpoint-down(sm)
			{
				float: left;
			}

		}

		.navbar-contents
		{
			@include transition;
			float: right;
			margin-top: 30px;
			margin-bottom: 0px;
			// @include clearfix;

			@media (max-width: 1160px) 
			{
				margin-top: 20px;
			}

			@media (max-width: 1050px) 
			{
				float: none;
				text-align: center;
			}

			@include media-breakpoint-down(sm)
			{
				display: none;
				margin-top: 0px;
			}

			.nav-contianer 
			{
				display: inline-block;	
				@include media-breakpoint-down(md)
				{
					display: block;
					ul
					{
						display: inline-block;
					}
				}

				@include media-breakpoint-down(sm)
				{
					display: block;
					ul
					{
						display: block;
						text-align: right;

						li
						{
							float: none;
						}
					}
				}
			}

			.primary-nav-container
			{
				ul
				{
					li
					{
						a
						{
							@include font-s($font-lg + rem-calc(6), $weight-regular, 1.2, $white-color);
							@include position(relative, 5);
							margin: 6px 20px;
							@media (max-width: 1160px) 
							{
								margin: 6px 15px;
							}

							@media (max-width: 1050px) 
							{
								margin: 6px 20px;
							}

							&:after {
								content: '';
								height: 27px;
								background: $white-color;
								width: 2px;
								position: absolute;
								right: -20px;
								top: 50%;
								@include transform(translate(0, -50%));
							}

							&:before
							{
								@include transition;
								content: '';
								height: 2px;
								background: $white-color;
								width: 0;
								position: absolute;
								right: 0;
								left: 50%;
								bottom: -3px;
							}

							@include media-breakpoint-down(sm)
							{
								&:after
								{
									display: none;
								}
							}
						}

						&:hover, &:active, &:focus, &.active
						{
							a
							{
								&:before
								{
									width: 100%;
									left: 0;
								}
							}
						}

						&:last-child
						{
							a
							{
								&:after {
									display: none;
								}
							}
						}
					}
				}
			}

			.secondary-nav-container
			{
				.btn-container
				{
					.btn-rent-facility
					{
						width: 200px;
						padding: 7px 0;
						border-radius: 0px;
						@include font-s($font-lg + rem-calc(6), $weight-regular, 1, inherit);
					}
				}
			}
		}

		.navbar-toggler
		{
			.ic-menu
			{
				&:before
				{
					font-weight: 900;
					font-family: $font-fontawesome;
					content: '\f0c9';
				}
			}
			&.open
			{
				.ic-menu:before
				{
					content: '\f00d';
				}	
			}
			color: $white-color;
			padding: 23px 10px;
			font-size: rem-calc(30);	
			float: right;
			display: none;
			@include clearfix;
			@include media-breakpoint-down(sm)
			{
				display: block;
			}
			@include media-breakpoint-down(xs)
			{
				padding: 9px 10px;
			}
		}
	}

	.sub-nav-bar
	{
		@include transition;
		background-color: rgba($white-color, 0.75);
		.sub-nav-inner
		{
			.sub-nav-title
			{
				padding: 10px 0;
				margin: 0;
				@include font-p($font-base - rem-calc(1), $weight-regular, 1.2, inherit);
				display: inline-block;
				width: 250px;
				text-align: center;

				@media (max-width: 1160px) 
				{
					width: 200px;
					font-size: rem-calc(10);
				}

				@media (max-width: 1050px) 
				{
					width: 100%;
					font-size: $font-base - rem-calc(1);
					text-align: center;
				}

				@include media-breakpoint-down(sm)
				{
					text-align: left;
				}

				@include media-breakpoint-down(xs)
				{
					font-size: rem-calc(10);
				}
			}
		}
	}

	&.nav-sticky
	{
		.main-navbar-container
		{
			.navbar-brand
			{
				img
				{
					width: 150px;
				}	
			}

			.navbar-contents
			{
				margin-top: 5px;
				@include media-breakpoint-down(sm)
				{
					display: none;
					margin-top: 0px;
				}
			}

			.navbar-toggler
			{
				padding: 9px 10px;
			}
		}

		.sub-nav-bar
		{
			display: none;
		}

	}
}