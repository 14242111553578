/*----------  Index View  ----------*/

main
{
	&.padded
	{
		padding-top: 50px;
	}
}

.section
{
	@include background-defaults;
	padding: 120px 0;
	@include media-breakpoint-down(sm)
	{
		padding: 80px 0;
	}
	@include media-breakpoint-down(xs)
	{
		padding: 60px 0;
	}
}

.section-banner
{
	padding:0;
	padding-top: 140px;
	@media (max-width: 1050px) 
	{
		padding-top: 200px;
	}

	@include media-breakpoint-down(md)
	{
		padding-top: 250px;
	}

	@include media-breakpoint-down(sm)
	{
		padding-top: 250px;
	}

	@include media-breakpoint-down(xs)
	{
		padding-top: 175px;
	}

	@media (max-width: 400px) 
	{
		padding-top: 110px;
	}

	.banner-inner-wrapper
	{
		@include position(relative, 5);
		min-height: 670px;
		@include media-breakpoint-down(sm)
		{
			min-height: 500px;
		}
		@include media-breakpoint-down(xs)
		{
			min-height: 400px;
		}
	}

	.banner-inner
	{
		@include position(absolute, 3);
		top: 50%;
		@include transform(translateY(-50%));

		p
		{
			@include font-helvetica($font-xl - rem-calc(4), $weight-regular, 1.2, $white-color);
			font-style: italic;
			text-align: center;

			@include media-breakpoint-down(md)
			{
				font-size: rem-calc(28);
			}
			@include media-breakpoint-down(xs)
			{
				font-size: rem-calc(20);
			}
		}

		.btn
		{
			margin: 50px 0;
			@include media-breakpoint-down(xs)
			{
				margin: 25px 0;
			}
		}
	}
}

.section-recreation
{
	padding: 28px 0 0px 0;
	.amenities
	{
		.amenity-item
		{
			margin: 0 40px;
			.amenity-title
			{
				margin: 20px 0;
				@include font-p(rem-calc(40), $weight-regular, 1, inherit);
			}

			@include media-breakpoint-down(md)
			{
				margin: 0 15px;
				.amenity-img
				{
					width: 75%;
				}
				.amenity-title
				{
					font-size: rem-calc(24);
				}
			}
			@include media-breakpoint-down(md)
			{
				margin: 0 5px;
				.amenity-img
				{
					width: 50%;
				}
				.amenity-title
				{
					font-size: rem-calc(18);
					margin: 10px 0 20px 0;
				}
			}

			@include media-breakpoint-down(xs)
			{
				.amenity-img
				{
					width: 80px;
				}
			}
		}
	}
	.recreation-inner
	{
		text-align: center;

		.btn-recreation
		{
			@include font-s(rem-calc(60), $weight-regular, 1.2, inherit);
			letter-spacing: 5px;
			padding: 10px 105px;
			width: auto;
			margin: 75px 0;

			.icon
			{
				@include transform(rotate(0deg));
				@include transition;
				font-size: 40px;
				padding: 2px 5px 0px 10px;
				margin-left: 50px;
				border-radius: 999px;
				border: 4px solid;
				vertical-align: text-top;
				margin-top: 6px;
			}

			&.open
			{
				.icon
				{
					@include transform(rotate(135deg));
				}
			}

			@include media-breakpoint-down(md)
			{
				padding: 10px 60px;
				margin: 50px 0;
				font-size: rem-calc(32);

				.icon
				{
					margin-top: 0;
    				padding: 0px 1px 0px 5px;
					margin-left: 20px;
					font-size: rem-calc(26);
					border-width: 2px;
				}
			}

			@include media-breakpoint-down(xs)
			{
				padding: 10px 30px;
				margin: 30px 0;
				font-size: rem-calc(26);
				letter-spacing: 1px;
				.icon
				{
					margin-top: 0px;
    				padding: 2px 4px 2px 5px;
					margin-left: 10px;
					font-size: rem-calc(16);
					border-width: 2px;
				}
			}
		}
	}

	.cnt_recreation_details
	{
		display: none;
	}

	.recreation-content-container
	{
		margin-top: 70px;
		position: relative;
		
		.container, .recreation-content-inner, .recreation-block, .row, .cs-col
		{
			position: static;
		}

		.recreation-block
		{
			.recreation-block-inner
			{
				height: 390px;
				@include media-breakpoint-down(xs)
				{
					height: auto;
				}
			}
			
			.recreation-block-content
			{
				padding: 55px 45px;
				@include media-breakpoint-down(xs)
				{
					padding: 30px 45px;
				}
			}

			.cs-col
			{
				order: 1;
			}
			.recreation-block-image
			{
				@include background-defaults;
				position: absolute;
				right: 50%;
				left: 0;
				.recreation-block-img
				{
					display: none;
				}

				@include media-breakpoint-down(xs)
				{
					position: relative;
					left: auto;
					right: auto;
					margin: 0 -15px;

					.recreation-block-img
					{
						display: block;
					}
				}
			}

			&.inverse
			{
				.recreation-block-image-wrapper
				{
					order: 2;
				}	

				.recreation-block-image
				{
					right: 0;
					left: 50%;
				}

				@include media-breakpoint-down(xs)
				{
					.recreation-block-image-wrapper
					{
						order: 1;
					}
					.recreation-block-image
					{
						left: auto;
						right: auto;
					}	
				}
			}

			li
			{
				@include font-s(rem-calc(40), $weight-regular, 1.5, $white-color);
				padding-left: 25px;
				position: relative;
				text-transform: uppercase;
				letter-spacing: 1px;

				&:before
				{
					font-weight: 900;
					font-family: $font-fontawesome;
					content: '\f111';
					position: absolute;
					left: 0;
					top: 23px;
					font-size: 8px;
				}

				@include media-breakpoint-down(md)
				{
					font-size: rem-calc(30);
					line-height: 1.2;

					&:before
					{
						top: 13px;
					}
				}
			}
		}
	}
}

.section-lodging
{
	color: $white-color;
	h1
	{
		margin-bottom: 40px;
	}
	
	p
	{
		@include font-s(rem-calc(77), $weight-regular, 1.2, $white-color);
		text-transform: uppercase;
		@include max-width(860px);
		margin-bottom: 80px;

		@include media-breakpoint-down(md)
		{
			margin-bottom: 60px;
			font-size: rem-calc(60);
		}
		@include media-breakpoint-down(sm)
		{
			text-align: center;
			margin-bottom: 40px;
			font-size: rem-calc(45);
		}
		@include media-breakpoint-down(xs)
		{
			margin-bottom: 30px;
			font-size: rem-calc(32);
		}
	}
}

.section-about
{
	text-align: center;

	h1
	{
		margin-bottom: 35px;

		@include media-breakpoint-down(sm)
		{
			margin-bottom: 20px;
		}
	}
}

.section-legacy
{
	min-height: 565px;
	position: relative;

	.legacy-inner
	{
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
	}

	@include media-breakpoint-down(sm)
	{
		min-height: 400px;
	}
}
.section-meeting
{
	.meeting-inner
	{
		h1
		{
			margin-bottom: 30px;
		}

		.meeting-block
		{
			margin: 35px 0;
			text-align: left;

			&:last-child {
				margin-bottom: 0;
			}
			
			.cs-col
			{
				order: 1;
			}

			&.inverse
			{
				.cs-col
				{
					&:nth-of-type(odd) {
						order: 2;
					}
					
				}

				@include media-breakpoint-down(sm)
				{
					.cs-col
					{
						order: 1 !important;
					}
				}
			}

			h3
			{
				@include font-p(rem-calc(53), $weight-regular, 1.2, inherit);

				@include media-breakpoint-down(md)
				{
					font-size: rem-calc(40);
				}
				@include media-breakpoint-down(sm)
				{
					font-size: rem-calc(32);
				}
			}

			.meeting-block-image-container
			{
				img
				{
					border-width: 6px;
					border-style: solid;
					border-radius: 6px;
				}
			}

			.meeting-block-content-container
			{
				padding: 20px 0;
				@include media-breakpoint-down(md)
				{
					padding: 0;
				}
				@include media-breakpoint-down(sm)
				{
					padding: 20px 0;
				}
			}


			&:nth-child(odd)
			{
				.meeting-block-content-container
				{
					padding-right: 50px;
					@include media-breakpoint-down(sm)
					{
						padding-right: 0;
					}
				}	
			}

			&:nth-child(even)
			{
				.meeting-block-content-container
				{
					padding-left: 50px;
					@include media-breakpoint-down(sm)
					{
						padding-left: 0;
					}
				}	
			}
		}
	}
}